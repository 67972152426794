import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="section">
      <div className="container">
        <div
          className="has-text-centered columns is-centered is-vcentered"
          style={{ height: 400 }}
        >
          <div>
            <h1>Page non trouvée</h1>
            <p>Cette page n'existe pas ou n'existe plus.</p>
            <p>
              Nous vous invitons à repasser par la{" "}
              <Link to="/">page d'accueil</Link> de notre site.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
